<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Costs</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <p>Vehicle running costs</p>
        <v-text-field
          label="Van running costs per mile"
          type="number"
          step="0.01"
          v-model="costs[0].amount"></v-text-field>
        <v-text-field
          label="Lorry running costs per mile"
          type="number"
          step="0.01"
          v-model="costs[1].amount"></v-text-field>
        <v-text-field
          label="Multimark running costs per mile"
          type="number"
          step="0.01"
          v-model="costs[2].amount"></v-text-field>
      </v-col>
      <v-col class="col-3">
        <p>Materials costs</p>
        <v-text-field
          label="Paint cost per M2"
          type="number"
          step="0.01"
          v-model="costs[6].amount"></v-text-field>
        <v-text-field
          label="Thermoplastic cost mer M2"
          type="number"
          step="0.01"
          v-model="costs[7].amount"></v-text-field>
      </v-col>
      <v-col class="col-3">
        <p>Other job costs</p>
        <v-text-field
          label="Waste disposal (% of job)"
          type="number"
          step="0.01"
          v-model="costs[8].amount"></v-text-field>
        <v-text-field
          label="Salary costs (% of job)"
          type="number"
          step="0.01"
          v-model="costs[9].amount"></v-text-field>
      </v-col>
      <v-col class="col-3">
        <p>Pay costs</p>
        <v-text-field
          label="Holiday pay uplift (multiplier)"
          type="number"
          step="0.01"
          v-model="costs[3].amount"></v-text-field>
        <v-text-field
          label="NI Rate (multiplier)"
          type="number"
          step="0.01"
          v-model="costs[4].amount"></v-text-field>
        <v-text-field
          label="Overnight rate"
          type="number"
          step="0.01"
          v-model="costs[5].amount"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-btn @click="updateCosts" small>Save changes</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminCosts',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      costs: [],
    };
  },
  methods: {
    updateCosts() {
      axios.post(`/costs/update/.json?token=${this.token}`, this.costs)
        .then((response) => {
          this.costs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCosts() {
      axios.get(`/costs/getAll.json?token=${this.token}`)
        .then((response) => {
          this.costs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getCosts();
  },
};
</script>
